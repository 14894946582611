export default {
    mamespaced : true,
    state: { 
        rs_dinas: [], 
        dinas : [],
        rs_sub_kegiatan : [],
        sub_kegiatan : [],
        count_data : [],
        rumpun : [],
        input_sub_kegiatan : [],
        st_asb : [],
        objek_belanja : [],
        rs_Data : []
    },
    mutations: { 
        //dinas
        set_rs_dinas_ssh(state, value) {
            state.rs_dinas = value 
        },
        set_dinas_ssh(state, value) {
            state.dinas = value 
        },
        set_rs_Data(state, value) {
            state.rs_Data = value 
        },
        //

        //dinas
        set_rs_sub_kegiatan_ssh(state, value) {
            state.rs_sub_kegiatan = value 
        },
        set_sub_kegiatan_ssh(state, value) {
            state.sub_kegiatan = value 
        },
        set_count_data_ssh(state, value) {
            state.count_data = value 
        },
        //
        set_rumpun_ssh(state, value){
            state.rumpun = value 
        },
        set_input_sub_kegiatan_ssh(state, value){
            state.input_sub_kegiatan = value 
        },
        set_st_asb_ssh(state, value){
            state.st_asb = value 
        },
        set_objek_belanja_ssh(state, value){
            state.objek_belanja = value 
        },

 
    },
    actions: {
   },
   
}
